.rdg-row {
    &.success {
        .rdg-cell {
            background-color: rgb(26 225 26 / 13%);
        }
    }

    &.error {
        .rdg-cell {
            background-color: rgb(225 26 26 / 13%);
        }
    }

    &.cancelled {
        .rdg-cell {
            text-decoration: line-through;
        }
    }
}
